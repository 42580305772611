import React, { useState } from 'react';
import axios from 'axios';
import './Caliber.css';

const counselorGroups = [
    {
        groupName: 'Trauma Specialist',
        level: 'Entry-Level',
        specialization: 'Trauma & Crisis Intervention',
        training: 'Basic Certification',
        experience: '< 1 year',
        severity: 'Two',
        counselors: 27
    },
    {
        groupName: 'Trauma Specialist',
        level: 'Experienced',
        specialization: 'Trauma Intervention',
        training: 'Advanced Certification',
        experience: '3-5 years',
        severity: 'Three',
        counselors: 18
    },
    {
        groupName: 'Suicide Prevention',
        level: 'Entry-Level',
        specialization: 'Suicide Prevention',
        training: 'Basic Certification',
        experience: '< 1 year',
        severity: 'Two',
        counselors: 33
    },
    {
        groupName: 'Suicide Prevention',
        level: 'Advanced',
        specialization: 'Suicide Prevention',
        training: 'Advanced Certification',
        experience: '> 5 years',
        severity: 'Four',
        counselors: 16
    },
    {
        groupName: 'Substance Abuse Specialist',
        level: 'Intermediate',
        specialization: 'Substance Abuse & Addiction Counseling',
        training: 'Intermediate Certification',
        experience: '1-3 years',
        severity: 'Two',
        counselors: 24
    },
    {
        groupName: 'Substance Abuse Specialist',
        level: 'Veteran',
        specialization: 'Addiction Counseling',
        training: 'Advanced Certification',
        experience: '> 5 years',
        severity: 'Three',
        counselors: 17
    },
    {
        groupName: 'Youth Crisis Specialist',
        level: 'Entry-Level',
        specialization: 'Youth Crisis Intervention',
        training: 'Basic Certification',
        experience: '< 1 year',
        severity: 'One',
        counselors: 35
    }
];

const Caliber = () => {
    const [userInput, setUserInput] = useState('');
    const [response, setResponse] = useState('');

    const handleInputChange = (event) => {
        setUserInput(event.target.value);
    };

    const handleSubmit = async () => {
        try {
            // Make the API call to OpenAI using GPT-4 Turbo
            const openAiResponse = await axios.post(
                'https://api.openai.com/v1/chat/completions',
                {
                    model: 'gpt-4-turbo',
                    messages: [
                        { role: 'system', content: 'You are a triage system that classifies cases for child helpline counselors. Your task is to evaluate the severity of a young person’s situation based on specific characteristics and assign a severity rating between 1 and 4, where 1 is the least severe and 4 is the most severe.' },
                        { role: 'user', content: `
                        A young person has reached out with the following message: "${userInput}". 
                        
                        Please assess their situation carefully and classify the severity based on the following criteria:
            
                        1. **Stage of Principal Diagnosis**: Is the condition asymptomatic (Level 1), showing moderate manifestations (Level 2), major manifestations (Level 3), or catastrophic (Level 4)?
                        
                        2. **Complications**: Are there none or minor complications (Level 1), moderate complications less important than the principal diagnosis (Level 2), major complications as important as or more important than the principal diagnosis (Level 3), or catastrophic complications involving death or major disability (Level 4)?
                        
                        3. **Interactions**: Are the social or personal interactions none or minor (Level 1), moderate (Level 2), major (Level 3), or catastrophic (Level 4)?
                        
                        4. **Dependency**: Is the person’s dependency on others for care low (Level 1), moderate (Level 2), major (Level 3), or extreme (Level 4)?
                        
                        5. **Procedures (Non-operating room)**: Are the procedures non-invasive or minor (Level 1), therapeutic or involve invasive diagnostic procedures (Level 2), require non-emergency life support (Level 3), or emergency life support (Level 4)?
                        
                        6. **Response to Therapy**: Is the response prompt with none or minor residual effects (Level 1), involves a moderate delay and residual effect (Level 2), serious delay with major residual effects (Level 3), or no response and catastrophic residual effects (Level 4)?
            
                        Using these criteria, assign the situation a severity level between 1 and 4, where:
            
                        **1 = Minor**: Low risk, manageable with minimal intervention
                        **2 = Moderate**: Moderate risk, requires intervention but is not immediately life-threatening
                        **3 = Major**: High risk, significant intervention required, potentially life-threatening
                        **4 = Catastrophic**: Immediate and critical danger, requires emergency intervention
                        
                        Based on your evaluation, return only the severity level (1-4) and the appropriate counselor group name. Match the group to the following counselor categories based on severity:
            
                        - Level 2: Trauma Specialist (Entry-Level), Substance Abuse Specialist (Intermediate)
                        - Level 3: Trauma Specialist (Experienced), Substance Abuse Specialist (Veteran)
                        - Level 4: Suicide Prevention (Advanced), Trauma Specialist (Advanced)
            
                        After determining the severity, respond in this format:
                        **Severity: [Level 1-4]**
                        **Group: [GroupName]**
                        ` }
                    ],
                    max_tokens: 4096,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer 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`, // Replace with your OpenAI API key
                    },
                }
            );

            // Ensure the response is valid and extract the content
            const gptResponseText = openAiResponse?.data?.choices?.[0]?.message?.content;

            if (!gptResponseText) {
                throw new Error('No valid response from OpenAI');
            }

            // Pass the valid response text to the extractSeverity function
            const severity = extractSeverityFromResponse(gptResponseText);

            // Counselor groups (same mock data as before)
            const counselorGroups = [
                {
                    groupName: 'Trauma Specialist Two',
                    severity: 'Two',
                },
                {
                    groupName: 'Trauma Specialist Three',
                    severity: 'Three',
                },
                {
                    groupName: 'Suicide Prevention Two',
                    severity: 'Two',
                },
                {
                    groupName: 'Suicide Prevention Four',
                    severity: 'Four',
                },
                {
                    groupName: 'Substance Abuse Specialist Two',
                    severity: 'Two',
                },
                {
                    groupName: 'Substance Abuse Specialist Three',
                    severity: 'Three',
                },
                {
                    groupName: 'Youth Crisis Specialist One',
                    severity: 'One',
                },
            ];

            // Match the severity with the correct counselor group
            const matchedGroup = counselorGroups.find(group => group.severity === severity);

            setResponse(matchedGroup ? matchedGroup.groupName : 'No suitable group found');
            
        } catch (error) {
            console.error("Error:", error);
            setResponse('An error occurred while fetching the counselor group.');
        }
    };

    // Helper function to extract severity from GPT response
    const extractSeverityFromResponse = (gptResponseText) => {
        if (typeof gptResponseText !== 'string') {
            console.error('Invalid GPT response text:', gptResponseText);
            return 'One'; // Default to the lowest severity
        }

        if (gptResponseText.includes('severity four') || gptResponseText.includes('critical')) {
            return 'Four';
        } else if (gptResponseText.includes('severity three')) {
            return 'Three';
        } else if (gptResponseText.includes('severity two')) {
            return 'Two';
        } else {
            return 'One';
        }
    };

    return (
        <div className="dashboard">
            <div className="sidebar">
                <div className="sidebar-top">
                    <img src="/Caliber_Logo.png" alt="Navbar Icon" className="navbar-icon" />
                </div>
                <div className="nav-items">
                    <ul>
                        <li>Segments</li>
                        <li>Practitioners</li>
                        <li>Triaging</li>
                        <li>Analytics</li>
                    </ul>
                </div>
            </div>
            <div className="content">
                <div className="header">
                    <h1>Segments</h1>
                    <button className="new-group">+ New Segment</button>
                </div>
                <div className="table_content">
                    <input type="text" placeholder="Search for Segment..." className="search-bar" />
                <table>
                    <thead>
                        <tr>
                            <th>Segment Name</th>
                            <th>Specialization</th>
                            <th>Training</th>
                            <th>Experience</th>
                            <th>Severity</th>
                            <th># of Practitioners</th>
                        </tr>
                    </thead>
                    <tbody>
                        {counselorGroups.map((group, index) => (
                            <tr key={index}>
                                <td>{group.groupName}</td>
                                <td>{group.specialization}</td>
                                <td>{group.training}</td>
                                <td>{group.experience}</td>
                                <td className={group.severity}>
                                        {group.severity}
                                </td>
                                <td>{group.counselors}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                </div>

                <div className="table_content">
                    <h2 className="test-headline"> Triaging Evaluation </h2>
                    <textarea value={userInput} onChange={handleInputChange}></textarea>
                    <button className="new-group" onClick={handleSubmit}> Submit </button>
                    <h3 className="test-headline"> Response: {response} </h3>
                </div>
            </div>
        </div>
    );
};

export default Caliber;